<!-- 招聘职位 -->
<template>
  <div class="mobile-container mobile-recruit" v-title="'招聘职位'">
    <div class="header">
      <mobile-header></mobile-header>
      <div class="filter">
        <input type="text" placeholder="请输入职位关键字" v-model="keyword">
        <div class="type-filter" @click="showShade = true">
          <span>筛选</span>
          <img src="@/assets/image/mobile/filter.png">
        </div>
      </div>
    </div>
    <div class="body">
      <div class="list">
        <div class="item" v-for="item in filterListData" :key="item.id" @click="goRecruitDetail(item)">
          <div class="item-title">
            <span class="recruit-status" v-if="item.type">{{ item.type }}</span>
            <span>{{ item.name }}</span>
          </div>
          <div class="item-body">
            <div class="address">{{ item.city }}</div>
            <div class="time">发布时间：{{ item.time }}</div>
          </div>
        </div>
      </div>
      <div class="tip">到底了~</div>
    </div>
    <!-- 类型筛选 -->
    <div class="shade" v-show="showShade" @click="showShade = false">
      <div class="shade-container" @click.stop>
        <div class="filter-type">职位类别</div>
        <div class="filter-type-list">
          <span class="filter-type-item" :class="{'active': item.select}" v-for="item in positin" :key="item.id" @click.stop="selectRecruitType(item)">{{ item.recruitType }}</span>
        </div>
        <div class="filter-type">工作地点</div>
        <div class="filter-type-list">
          <span class="filter-type-item" :class="{'active': item.select}" v-for="item in citys" :key="item.id" @click.stop="selectCity(item)">{{ item.city }}</span>
        </div>
        <div class="confirm-btn" @click="confirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  // 招聘数据
  import MobileRecruitData from './recruit-data'

  export default {
    name: 'MobileRecruit',
    data () {
      return {
        listData: Object.freeze(MobileRecruitData),
        positin: [],
        citys: [],
        showShade: false,
        keyword: '',
        selectRecruitTypeValue: '',
        selectCityValue: ''
      }
    },
    components: {
      MobileHeader
    },
    computed: {
      filterListData () {
        return this.listData.filter(item => item.name.indexOf(this.keyword) !== -1 && item.recruitType.indexOf(this.selectRecruitTypeValue) !== -1 && item.city.indexOf(this.selectCityValue) !== -1)
      }
    },
    methods: {
      // 选择职位类别
      selectRecruitType (row) {
        this.positin.forEach(item => {
          item.select = false
        })
        row.select = true
      },
      // 选择工作地点
      selectCity (row) {
        this.citys.forEach(item => {
          item.select = false
        })
        row.select = true
      },
      // 确定
      confirm () {
        let selectRecruitTypeArr = this.positin.filter(item => item.select)
        if (selectRecruitTypeArr.length > 0) {
          this.selectRecruitTypeValue = (selectRecruitTypeArr[0].recruitType === '全部' ? '' : selectRecruitTypeArr[0].recruitType)
        }
        let selectCityArr = this.citys.filter(item => item.select)
        if (selectCityArr.length > 0) {
          this.selectCityValue = (selectCityArr[0].city === '全部' ? '' : selectCityArr[0].city)
        }
        this.showShade = false
      },
      // 跳转至详情页
      goRecruitDetail (row) {
        this.$router.push({
          name: 'MobileRecruitDetail',
          query: {
            row: JSON.stringify(row)
          }
        })
      },
      // 初始化
      init () {
        let recruitTypeArr = [...new Set(this.listData.map(item => item.recruitType))]
        this.positin = recruitTypeArr.map((item, index) => {
          return {
            id: index + 1,
            recruitType: item,
            select: false
          }
        })
        this.positin.unshift({
          id: recruitTypeArr.length + 1,
          recruitType: '全部',
          select: false
        })
        let cityArr = [...new Set(this.listData.map(item => item.city))]
        this.citys = cityArr.map((item, index) => {
          return {
            id: index + 1,
            city: item,
            select: false
          }
        })
        this.citys.unshift({
          id: recruitTypeArr.length + 1,
          city: '全部',
          select: false
        })
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-container.mobile-recruit {
    font-family: Source Han Sans CN;
    .filter {
      background-color: $page-color;
      padding: $distance-1 $distance-2;
      display: flex;
      font-size: $font-size-3;
      font-weight: 400;
      color: $text-color-1;
      height: 0.66rem;
      box-sizing: content-box;
      input {
        width: 5.12rem;
        background: $white-color;
        border-radius: 4rpx;
        text-align: center;
      }
      .type-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.58rem;
        background: $white-color;
        border-radius: 4rpx;
        margin-left: $distance-1;
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: $distance-1;
        }
      }
    }
    .body {
      padding-top: 1.94rem;
      .list {
        .item {
          padding: $distance-2;
          background-color: $white-color;
          margin-bottom: $distance-1;
          .item-title {
            font-size: $font-size-1;
            font-weight: 400;
            color: $text-color-1;
            line-height: 0.36rem;
            .recruit-status {
              margin-top: 0.03rem;
            }
          }
          .item-body {
            margin-top: 0.38rem;
            font-weight: 300;
            .address {
              color: $text-color-3;
              font-size: $font-size-3;
            }
            .time {
              color: $text-color-4;
              font-size: $font-size-6;
              margin-top: 0.13rem;
            }
          }
        }
      }
      .tip {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        font-size: $font-size-2;
        font-weight: 400;
        color: $text-color-5;
        text-align: center;
      }
    }
    .shade {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 999;
      .shade-container {
        width: 6rem;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: $white-color;
        padding: $distance-2;
        box-sizing: border-box;
        font-size: $font-size-3;
        font-weight: 400;
        color: $text-color-1;
        .filter-type {
          padding-top: 0.08rem;
        }
        .filter-type-list {
          margin-top: $distance-2;
          display: flex;
          flex-wrap: wrap;
          .filter-type-item {
            width: 1.6rem;
            height: 0.64rem;
            background: $page-color;
            border-radius: 0.32rem;
            line-height: 0.64rem;
            text-align: center;
            margin-bottom: $distance-2;
            margin-right: $distance-2;
            &.active {
              background: $bg-color-2;
              color: $text-color-6;
            }
            &:nth-of-type(3n) {
              margin-right: 0;
            }
          }
        }
        .confirm-btn {
          position: absolute;
          left: $distance-2;
          right: $distance-2;
          bottom: $distance-2;
          height: 0.72rem;
          line-height: 0.72rem;
          border-radius: 0.36rem;
          text-align: center;
          background-color: $text-color-6;
          color: $white-color;
          font-size: $font-size-1;
          font-weight: 400;
        }
      }
    }
  }
</style>