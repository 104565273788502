export default [
  {
    id: '1',
    type: '急', // 是否紧急
    name: '工程主管', // 岗位名称
    recruitType: '工程技术', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 1, // 需求人数
    duty: [ // 岗位职责
      '负责现有产品深挖剖析，与行业内其他产品对比，建立产品线对外宣传体系，促进研发产品快速优化并通过项目落地应用；',
      '售前技术交流沟通，调研客户需求，参与工程项目洽谈并设计规划方案；',
      '负责项目方案细化规划设计，细化图纸、合同、投标文件、技术协议、商务文件的编写、制定与评审。'
    ],
    require: [ // 任职要求
      '机械设计制造及其自动化等相关机械专业，大学学历；',
      '具有3年以上物流自动化方案规划与细化工作经验；',
      '能够制定物流规划概念方案及参数，能独立做售前规划设计，具备招投标技术文件制定的能力；',
      '熟悉物流自动化行业知识，掌握常规物流场景规划与细化设计能力，掌握输送分拣系统设计能力；有立库和穿梭车库工作经验者优先。'
    ],
    email: 'inz56@jztey.com' // 简历投递邮箱
  },
  {
    id: '2',
    type: '急', // 是否紧急
    name: '电气工程师（堆垛机、输送线、提升机、分拣机方向）', // 岗位名称
    recruitType: '工程技术', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 4, // 需求人数
    duty: [ // 岗位职责
      '负责物流设备的电气选型、电气图纸、PLC程序的开发工作；',
      '负责物流设备现场程序调试与优化工作；',
      '对上位机软件进行编程与优化，通过上位软件诊断设备问题；',
      '参与公司现有设备的问题处理及优化改造工作。'
    ],
    require: [ // 任职要求
      '大专以上学历，电气控制、自动化相关专业；',
      '熟练Office办公软件；',
      '精通CAD、PLC编程软件（西门子&三菱）, 熟悉WINCC、组态王VB等上位机软件编程；',
      '三年以上物流行业自动化开发经验，具有物流设备（堆垛机、输送线、提升机、分拣机等）电气开发、现场调试与优化。能独立完成电气选型、电控图纸绘制、PLC程序开发与调试工作；',
      '具备良好的沟通能力，团队合作精神，自我学习能力；',
      '吃苦耐劳，能适应短期出差。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '3',
    type: '急', // 是否紧急
    name: '物流自动化仿真工程师', // 岗位名称
    recruitType: '物流技术', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 3, // 需求人数
    duty: [ // 岗位职责
      '建立三维模型，协助仿真团队完成仿真模型的搭建；',
      '通过flexsim进行物流规划方案的仿真验证及优化，输出仿真报告；',
      '搭建仓储物流中心仿真模型，验证分析仓储物流中心运营方案，为仓储物流中心运营优化提供决策依据；',
      '搭建数字孪生物流中心，实时获取仓储物流中心信息，监控物流中心运行、诊断异常、输出异常对应调整方案至仓储物流中心。'
    ],
    require: [ // 任职要求
      '计算机、自动化、工业工程、物流、机械等相关专业，本科以上学历；',
      '物流仿真项目经验2年及以上，能独立开展物流仿真相关项目；',
      '熟练使用sketchup或3D max；',
      '能够基于flexsim进行二次开发。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '4',
    type: '急', // 是否紧急
    name: '设备工程师（堆垛机、输送线、提升机、分拣机方向）', // 岗位名称
    recruitType: '工程技术', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 3, // 需求人数
    duty: [ // 岗位职责
      '完成设备项目计划制定；',
      '进行项目进度及过程质量的控制跟进与反馈；',
      '具有设备安装与调试排障答疑能力，能对现场反馈的设备（自动化输送分拣、堆垛机、AGV/RGV、机械手等）难题快速做出梳理，并提出成熟有效的解决方案；',
      '熟练现场安全管理规范，进行安全管理；',
      '进行项目的验收和成果的交付，完成项目问题的收集和资料的收集和整理。'
    ],
    require: [ // 任职要求
      '大专以上学历，电气控制、自动化、机械工程等相关专业；',
      '能熟练运用常用的办公软件和CAD绘图软件；',
      '有2年以上的设备项目施工和现场管理的经验，有自动化输送分拣、堆垛机、AGV/RGV、机械手相关项目经验优先考虑；',
      '具备良好的沟通能力，团队合作精神，自我学习能力；',
      '吃苦耐劳，能适应出差。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '5',
    type: '急', // 是否紧急
    name: '规划工程师', // 岗位名称
    recruitType: '物流规划', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 5, // 需求人数
    duty: [ // 岗位职责
      '承担方案售前工作；',
      '客户需求调研及需求分析；',
      '负责物流中心规划项目的物流集成方案设计、设备选型工作。'
    ],
    require: [ // 任职要求
      '物流、机械、自动化、工业工程等相关专业；',
      '3年及以上的物流系统规划集成设计或售前规划相关的工作经验；',
      '能独立进行客户需求调研，熟悉仓运配作业流程，了解物流作业运作逻辑和成本构成；',
      '熟悉物流建筑设计防火规范、掌握GSP政策对物流设计的要求；',
      '具备良好的团队合作精神，能够适应经常性出差和承受较大的工作压力；',
      '熟练使用AUTOCAD绘图软件。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '6',
    type: '急', // 是否紧急
    name: 'web前端工程师', // 岗位名称
    recruitType: '系统研发', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 5, // 需求人数
    duty: [ // 岗位职责
      '前端组件库的设计与开发；',
      '前端框架迭代；',
      '云开发平台的研发。'
    ],
    require: [ // 任职要求
      '前端基础扎实，精通javascript编程，熟悉前端性能优化；',
      '有良好的编程习惯和快速学习能力，能够深入技术研究，追求极致；',
      '有良好的面向对象开发思想，至少深入掌握一个主流库或者框架，如：React/Vue/Angular；',
      '熟悉nodejs及前端工程化，至少精通一种前端构建工具，如：webpack、gulp；',
      '具备良好的抽象设计能力，能封装复用性高、可维护性好的前端组件；',
      '有自助BI或者PAAS开发经验者优先；',
      '有webgl、three.js、thing.js开发经验者优先；',
      '有跨平台开发经验者优先。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '7',
    type: '急', // 是否紧急
    name: '物流项目实施工程师', // 岗位名称
    recruitType: '项目实施', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 6, // 需求人数
    duty: [ // 岗位职责
      '负新建、改造物流中心项目的规划图纸细化，设施设备采购招标、基建前期跟踪、现场布置、设备安装调试、系统设备联调测试、运营辅导、物流中心搬迁、系统上线及优化验收等各项工作；',
      '制定与指导实施系统上线物流方案，包括全面解决方案、人员配置、模拟运行方案、搬迁方案、系统切换方案等。'
    ],
    require: [ // 任职要求
      '物流管理相关专业，大专以上学历；',
      '具有扎实的物流专业知识，对物流管理、物流工程、供应链管理、运营管理及项目管理有一定的了解；',
      '熟练使用Office，CAD有一定了解，具有数据统计、数据分析及运用能力；',
      '具有较强的问题分析、解决及良好的沟通能力；',
      '工作细致，责任心强，有一定的团队协作精神；',
      '性格开朗，具有一定的抗压能力，能适应长期出差。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  },
  {
    id: '8',
    type: '急', // 是否紧急
    name: 'PMO管理', // 岗位名称
    recruitType: '项目管理', // 岗位类别
    city: '武汉', // 工作地点
    time: '2021/6/1', // 发布时间
    number: 2, // 需求人数
    duty: [ // 岗位职责
      '结合集团及物流总公司相关部门管理要求，在项目管理委员会指导下，完善日常项目管理机制、配套流程或操作标准并推进落实；',
      '组织开展云仓公司各类项目年度预算的制定、评审、执行和监控工作；',
      '定期发布项目执行状态报告，就异常事项与项目经理沟通改进措施并协调跨部门资源需求；',
      '组织开展项目运行质量考核评估及奖励分配相关工作。'
    ],
    require: [ // 任职要求
      '2年以上相关工作经验，拥有互联网或物流行业项目管理、项目交付、产品经理经验，具备scrum master、PMP、PMP-ACP或PRINCE2证书者优先；',
      '熟练使用项目管理工具，熟悉项目流程，优秀的问题分析和解决能力；',
      '熟悉项目流程，有一定的成本和风险管控意识及计划统筹能力；',
      '善于沟通，具有良好的团队合作精神和协作能力，有团队管理能力和项目质量及进度的把控能力；',
      '良好的个人素养，优秀的沟通技巧与组织能力、协调能力及责任感；',
      '具备一定的文档书写能力，善于工作总结和问题；',
      '有责任心，工作踏实，正直诚恳，积极上进能与公司共同成长。'
    ],
    email: 'inz56@jztey.com'  // 简历投递邮箱
  }
]